import Head from "next/head";

export default function Custom404() {
  return (
    <>
      <Head>
        <meta name="robots" content="noindex, nofollow" key="robots" />
      </Head>
      <div className="flex h-full w-full items-center justify-center">
        <h1>404 - Page Not Found</h1>
      </div>
    </>
  );
}
